<script setup lang="ts">
const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      borderWidth: 1,
    },
    {
      label: '# of People',
      data: [1, 9, 13, 15, 12, 3],
      borderWidth: 1,
    },
    {
      label: 'Cash',
      data: [10, 4, 7, 10, 16, 8],
      borderWidth: 1,
    },
  ],
}

const data2 = {
  labels: ['data 1', 'data 2', 'data 3'],
  datasets: [
    {
      data: [1, 2, 3],
    },
  ],
}

const myRefreshConst = ref(0)

setTimeout(() => {
  myRefreshConst.value++
}, 1000)
</script>

<template>
  <div class="flex flex-col w-full h-full p-4 gap-4 overflow-y-auto">
    <h1 class="font-extrabold text-lg">Playground Chartjs</h1>

    <!-- Better Multi datasets charts -->
    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Bar chart</h1>
      <ChartHandler :key="data" type="bar" :data="data" />
    </div>

    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Line chart</h1>
      <ChartHandler :key="data" type="line" :data="data" />
    </div>

    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Radar chart</h1>
      <ChartHandler :key="data" type="radar" :data="data" />
    </div>

    <!-- Charts that are useally better with 1 dataset but works in multi too -->
    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Pie chart</h1>
      <ChartHandler :key="data2" type="pie" :data="data2" />
    </div>

    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Doughnut chart</h1>
      <ChartHandler :key="data2" type="doughnut" :data="data2" />
    </div>

    <div class="flex flex-col gap-2 w-1/2">
      <h1 class="font-extrabold">Polar Area chart</h1>
      <ChartHandler :key="data2" type="polarArea" :data="data2" />
    </div>
  </div>
</template>
